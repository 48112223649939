<script setup lang="ts">
const ui = {
    base: 'overflow-hidden border border-neutral-300',
    divide: '',
    ring: 'ring-0',
    rounded: 'rounded-3xl',
    shadow: 'shadow-none',
    body: {
        base: 'p-3 flex flex-col gap-3',
        background: '',
        padding: '',
    },
    header: {
        base: 'p-0 overflow-hidden relative',
        background: 'bg-contain aspect-[4/3] max-w-full',
        padding: '',
    },
    footer: {
        base: 'px-4 pb-6 pt-0 ',
        background: '',
        padding: '',
    },
};
</script>

<template>
    <UCard :ui="ui">
        <template #header>
            <slot name="header"></slot>
        </template>
        <p class="overflow-hidden text-ellipsis whitespace-nowrap text-xs font-medium leading-4 tracking-normal text-gray-500">
            <slot name="class"></slot>
        </p>
        <p class="overflow-hidden text-ellipsis whitespace-nowrap text-base font-medium leading-6 tracking-normal">
            <slot name="model"></slot>
        </p>
        <p class="flex flex-row justify-between">
            <span class="flex flex-row items-center text-xs font-medium leading-4 tracking-normal lg:max-w-20">
                <UIcon class="h-5 w-5" name="i-mdi-bus" />
                <span class="ml-1 block overflow-x-hidden overflow-ellipsis text-nowrap"><slot name="registration"></slot></span>
            </span>
            <span class="flex flex-row items-center text-xs font-medium leading-4 tracking-normal lg:max-w-20">
                <UIcon class="h-5 w-5" name="i-mdi-gauge" />
                <span class="ml-1 block overflow-x-hidden overflow-ellipsis text-nowrap"><slot name="kilometer"></slot></span>
            </span>
            <span class="flex flex-row items-center text-xs font-medium leading-4 tracking-normal lg:max-w-20">
                <UIcon class="h-5 w-5" name="i-mdi-engine-outline" />
                <span class="ml-1 block overflow-x-hidden overflow-ellipsis text-nowrap"><slot name="euro-norm"></slot></span>
            </span>
        </p>

        <p v-if="$slots.price" class="flex items-center gap-3 text-3xl font-medium leading-9 tracking-normal"><slot name="price"></slot></p>

        <div v-if="$slots.status" class="min-h-6"><slot name="status"></slot></div>
    </UCard>
</template>
