import { type AuctionItemEntity, type AuctionListingItemEntity, AuctionType } from '~/apiClient';

export const isAuctionBundle = (auction?: AuctionItemEntity | AuctionListingItemEntity): boolean =>
    isAuctionBundleMulti(auction) || isAuctionBundleMono(auction);

export const isAuctionBundleMono = (auction?: AuctionItemEntity | AuctionListingItemEntity): boolean =>
    !!auction && (<AuctionType[]>[AuctionType.BundleMonoVersion, AuctionType.BundleMonoVersionToScrap]).includes(auction.type);

export const isAuctionBundleMulti = (auction?: AuctionItemEntity | AuctionListingItemEntity): boolean =>
    !!auction && (<AuctionType[]>[AuctionType.BundleMultiVersion, AuctionType.BundleMultiVersionToScrap]).includes(auction.type);

export const isAuctionMono = (auction?: AuctionItemEntity | AuctionListingItemEntity): boolean =>
    !!auction && (<AuctionType[]>[AuctionType.Mono, AuctionType.MonoToScrap]).includes(auction.type);
